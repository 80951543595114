import $ from 'jquery';
import datepickerFactory from 'jquery-datepicker';

datepickerFactory($);

const deposit = $( "#deposit-date" ).datepicker({
    onSelect: function(selected) {
        let today = $.datepicker.formatDate('mm/dd/yy', new Date);

        let start = new Date(today);
        let end = new Date(selected);
        let diff = (end - start) / (1000 * 60 * 60 * 24);

        if(diff >= 60){
            $('#deposit-over-60').slideDown();
            $('#over_60_check').val('true');
        } else {
            $('#deposit-over-60').slideUp();
            $('#over_60_check').val('false');
        }
    }
});