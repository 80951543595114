import $ from 'jquery';

var sliders = {
    form: function(){
        const slider = $('.form-slider')

        $(slider).slick({
            dots: true,
            arrows: false,
            infinite: false,
            speed: 400,
            fade: false,
            adaptiveHeight: false,
            autoplay: false,
            autoplaySpeed: 10000,
            cssEase: 'linear',
            centerMode: false,
            swipe: false,
            touchMove: false,
            draggable: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            swipeToSlide: false,
            appendDots: $('#form-slider-dots')
        });

        sliders.validation(slider);
    },

    controls: function(){
        const button = $('.form-button');

        $(button).on('click', function(){
            let form = $(this).closest('form');
            let slider = $(this).closest('.slick-slider');
            let direction = $(this).data('slick-direction');

            if(direction == 'next')
            {
                $(slider).slick('slickNext');
            } else if(direction == 'previous') {
                $(slider).slick('slickPrev');
            } else {
                console.log(form);
                $(form).submit();
            }
        });
    },

    validation: function(slider){
        const errors = $(slider).find('.form-error');

        $(errors).each(function(index, value){
            if(index == 0){
                let slide = $(this).closest('.slick-slide').data('slick-index');
                $(slider).slick('slickGoTo', slide);
            }
        });

    },

    init: function(){
        sliders.form();
        sliders.controls();
    }
}

sliders.init();