require('jquery');
require('jquery-ui');
require('jquery-datepicker');
require('@popperjs/core');
require('bootstrap');
require('@fortawesome/fontawesome-free');
require('slick-carousel');
require('smooth-scroll');


// Components
require('./components/sliders');
require('./components/alerts');

// Plugins
require('./plugins/smooth-scroll');
require('./plugins/datepicker');