import $ from 'jquery';
import SmoothScroll from 'smooth-scroll';

var alerts = {
    transition: function(){
        const alert = $('.alert');
        let section = $(alert).data('section');

        setTimeout(function(){
            $(alert).removeClass('alert-transition');
        }, 1000);

        if(section){
            var scroll = new SmoothScroll();
            var anchor = document.querySelector(section);
            scroll.animateScroll(anchor);
        }
    }
}

$(document).ready(function(){
    alerts.transition();
});